@import "../vars_smart";

#error-page {
  background-color: $acte2i-color;
  padding: 150px 0 50px;
  
  h1 {
    border: none;
    font-size: 200px;
    line-height: 200px;
    font-weight: bold;
    font-family: 'Impact', 'sans-serif';
    color: white;
    margin-bottom: 50px;
  }
  
  .interdit {
    font-size: 35px;
    font-weight: bold;
    color: white;
  }
  
  .refus {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
  }
  
  a {
    font-size: 22px;
    color: white;
    
    &:hover {
      text-decoration: underline;
    }
  }
}
