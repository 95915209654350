$primary-bg-color: #4b70df;
$primary-text-color: white;

$secondary-bg-color: #f2f2f2;
$secondary-text-color: #555;

$info-bg-color: #00c0ef;
$info-text-color: white;

$danger-bg-color: #f56954;
$danger-text-color: white;

$success-bg-color: #00cb70;
$success-text-color: white;

$warning-bg-color: #f39c12;
$warning-text-color: white;

$pink-bg-color: #ff3299;
$pink-text-color: white;

$dark-bg-color: #555;
$dark-text-color: white;

$bg-main-color: #4b70df;
$width-sidebar: 260px;
$duration-transition-sidebar: 180ms;

//----- Acte2i variables -----//

$acte2i-color: #0b5ba0;
$entrant-color: #68a1e5;
$sortant-color: #2ecc71;
$relecture-color: #f39c12;

//----- Theme variables -----//

$primary: #0a67a3;
$content-bg-color: #f4f7fa;
$border-color: #ccc;

$topbar-bg-color: #fff;
$topbar-text-color: #555;
$topbar-link-hover-bg-color: #eee;

$sidebar-bg-color: rgb(49, 58, 70);
$sidebar-text-color: rgb(131, 145, 162);
$sidebar-active-bg-color: #29303a;
$sidebar-hover-bg-color: lighten($sidebar-bg-color, 3%);

$frame-font-family: 'Roboto','Helvetica Neue','Helvetica','Arial';

//----- Autres variables -----//

$orangeEspaceClient: #f2891a;
